import { VelocityService } from './velocity.service';
import { AfterViewInit, Component, ViewChild } from '@angular/core';

import { ChartBase, ChartType, GoogleChartComponent } from 'angular-google-charts';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  @ViewChild('googlechart1', { static: true })
  private googlechart1!: GoogleChartComponent;

  @ViewChild('googlechart2', { static: true })
  private googlechart2!: GoogleChartComponent;

  public chart1 = {
    type: ChartType.Gauge,
    data: [
      ['Senden', 0]
    ],
    options: {
      width: 800,
      height: 800,
      minorTicks: 10,
      max: 500
    }
  };

  public chart2 = {
    type: ChartType.Gauge,
    data: [
      ['Status', 0]
    ],
    options: {
      width: 800,
      height: 800,
      minorTicks: 10,
      max: 500,
    }
  };

  title = 'Elaine Geschwindigkeiten';

  maxSend = 500;
  maxStatus = 500;

  constructor(private veloService: VelocityService) {
    setInterval(() => {
      const vs = this.veloService.getVelos().subscribe(v => {
        this.chart1.data = [
          ['Senden', v.send ]
        ];
        this.chart2.data = [
          ['Status', v.status ]
        ];
        this.maxSend = Math.max(this.maxSend, v.send);
        this.maxStatus = Math.max(this.maxStatus, v.status);
        this.chart1.options = Object.assign(this.chart1.options, { max: this.maxSend });
        this.chart2.options = Object.assign(this.chart2.options, { max: this.maxStatus });
        vs.unsubscribe();
      });
    }, 1_000);
  }

}
