import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Velos } from './Velos';

@Injectable({
  providedIn: 'root'
})
export class VelocityService {

  constructor(private http: HttpClient) {
  }

  public getVelos(): Observable<Velos> {
    return this.http.get('/velocity') as Observable<Velos>;
  }

}
