<div class="main-container">

  <h1>{{ title }}</h1>

  <table>
    <tr>
      <td>
        <google-chart #googlechart1
          [type]="chart1.type"
          [data]="chart1.data"
          [options]="chart1.options">
        </google-chart>
      </td>
      <td>
        <google-chart #googlechart2
          [type]="chart2.type"
          [data]="chart2.data"
          [options]="chart2.options">
        </google-chart>
      </td>
    </tr>
  </table>

</div>
